<template>
  <div class="policy-list-content pt-4 overflow-hidden w-100">
    <custom-scrollbar>
      <b-container
        fluid
        class="pl-5 pr-5"
      >
        <div class="d-flex align-items-center justify-content-between w-100 mb-4">
          <h3>
            Список полисов
          </h3>
          <div class="d-flex align-items-center">
            <b-button
              v-if="checkFeatureAccess({ name: 'Кнопка Объединить полисы', url: '/policy-list' })"
              variant="success"
              :type="$const.PRIMARY_BUTTON"
              class="mr-2"
              size="sm"
              @click="openCombinePolicyModal"
            >
              <b-icon
                icon="arrow-repeat"
                class="mr-1"
              />
              <span>
                Объединить полисы
              </span>
            </b-button>

            <b-button
              v-if="checkFeatureAccess({ name: 'Кнопка Добавить полис', url: '/policy-list' })"
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              @click="openPolicyCreateModal"
            >
              <b-icon
                icon="plus"
                class="mr-1"
              />
              <span>
                Добавить полис
              </span>
            </b-button>
          </div>
        </div>
        <b-row class="mb-4">
          <b-col class="w-100">
            <div class="d-flex">
              <b-input-group class="bg-white rounded">
                <b-form-input
                  v-model="policyNumber"
                  :disabled="isBusy"
                  type="search"
                  placeholder="Введите номер полиса"
                  @keyup.enter="clearPageAndSearch"
                />
                <b-form-input
                  v-model="personName"
                  :disabled="isBusy"
                  type="search"
                  placeholder="Введите ФИО"
                  @keyup.enter="clearPageAndSearch"
                />
              </b-input-group>
              <b-button
                :disabled="isBusy"
                variant="primary"
                :type="$const.PRIMARY_BUTTON"
                class="rounded ml-2 border-secondary"
                @click="clearPageAndSearch"
              >
                <b-icon icon="search" />
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-overlay
          v-if="!policyList || Object.keys(policyList).length === 0"
          :show="isLoading"
          variant="transparent"
          no-wrap
        />
        <div v-if="policyList">
          <div v-if="Object.keys(policyList).length !== 0">
            <b-table
              v-if="policyList"
              :fields="fields"
              :items="policyList"
              :busy="isBusy"
              :per-page="0"
              :current-page="currentPage"
              class="bg-white text-center overflow-auto"
              bordered
              responsive
              hover
              :small="true"
            >
              <template v-slot:cell(startDate)="row">
                <span>{{ startDateFormated(row) }}</span>
              </template>
              <template v-slot:cell(endDate)="row">
                <span>{{ endDateFormated(row) }}</span>
              </template>
              <template v-slot:cell(birthday)="row">
                <span>{{ birthdayDateFormated(row) }}</span>
              </template>
              <template v-slot:cell(hasIndemnityLetters)="row">
                <b-badge
                  v-if="row.item.hasIndemnityLetters"
                  variant="success"
                >
                  Да
                </b-badge>
                <span v-else>
                  Нет
                </span>
              </template>
              <template v-slot:cell(hasConsultations)="row">
                <b-badge
                  v-if="row.item.hasConsultations"
                  variant="primary"
                >
                  Да
                </b-badge>
                <span v-else>
                  Нет
                </span>
              </template>
              <template v-slot:cell(firstName)="row">
                <span>{{ `${row.item.lastName} ${row.item.firstName} ${row.item.middleName}` }}</span>
              </template>
              <template v-slot:cell(isActivated)="row">
                <b-badge
                  v-if="row.item.isActivated"
                  variant="success"
                >
                  Активирован
                </b-badge>
                <b-badge
                  v-else
                  variant="danger"
                >
                  Не активирован
                </b-badge>
              </template>
              <template #cell(actions)="row">
                <div class="d-flex align-items-center">
                  <b-button
                    variant="primary"
                    size="sm"
                    :type="$const.PRIMARY_BUTTON"
                    @click="openEditUserModal(row)"
                  >
                    <b-icon icon="pencil-fill" />
                  </b-button>
                </div>
              </template>
              <template #cell(delete)="row">
                <b-button
                  v-if="checkFeatureAccess({ name: 'Кнопка удаления полиса', url: '/policy-list' })"
                  variant="danger"
                  size="sm"
                  class="ml-1"
                  :type="$const.PRIMARY_BUTTON"
                  @click="openDeleteUserModal(row.item.id)"
                >
                  <b-icon icon="trash" />
                </b-button>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2" />
                  <strong>Загрузка...</strong>
                </div>
              </template>
            </b-table>
            <div
              class="mt-3"
            >
              <div
                v-show="policyList"
                class="crm-pagination"
              >
                <b-dropdown
                  id="dropdown-1"
                  :text="`${perPage}`"
                  split
                  class="m-md-2"
                  size="sm"
                  split-variant="outline-primary"
                  variant="primary"
                >
                  <b-dropdown-item @click="tableSizeHandler(10)">
                    10
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item @click="tableSizeHandler(25)">
                    25
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item @click="tableSizeHandler(50)">
                    50
                  </b-dropdown-item>
                </b-dropdown>
                <b-pagination
                  v-model="currentPage"
                  pills
                  :total-rows="+allCount"
                  :per-page="perPage"
                  style="padding-top: 16px"
                  @input="policyGet"
                />
              </div>
            </div>
          </div>
          <span v-else>Ничего не найдено</span>
        </div>
      </b-container>
    </custom-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as types from '@/store/types';
import { format, parseISO } from '@evd3v/date-fns';
import { mixinRoles } from '@/mixins';
import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'PolicyList',
  page: {
    title: 'CRM Doctis - Список полисов',
  },
  components: {},
  mixins: [mixinRoles],
  data() {
    return {
      fields: [
        {
          key: 'delete',
          label: '',
          tdClass: 'align-middle',
        },
        {
          key: 'id',
          label: 'ID полиса',
          tdClass: 'align-middle',
          thClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'policyId',
          label: 'Номер полиса',
          tdClass: 'align-middle',
          thClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'startDate',
          label: 'Дата начала',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'endDate',
          label: 'Дата окончания',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'firstName',
          label: 'ФИО',
          tdClass: 'align-middle',
          thClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'birthday',
          label: 'Дата рождения',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'hasIndemnityLetters',
          label: 'ГП',
          tdClass: 'align-middle',
        },
        {
          key: 'hasConsultations',
          label: 'Конс.',
          tdClass: 'align-middle',
        },
        {
          key: 'isActivated',
          label: 'Статус полиса',
          tdClass: 'align-middle',
        },
        {
          key: 'companyName',
          label: 'Страховая компания',
          tdClass: 'align-middle',
        },
        {
          key: 'programName',
          label: 'Страховая программа',
          tdClass: 'align-middle',
        },
        {
          key: 'insuranceName',
          label: 'Страховая подпрограмма',
          tdClass: 'align-middle',
        },
        {
          tdClass: 'align-middle',
          key: 'actions',
          label: '',
        },
      ],
      currentPage: 1,
      perPage: 10,
      policyNumber: null,
      personName: null,
      limit: 10,
      allCount: null,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: types.POLICY_IS_LOADING_GET,
      policySearchTrigger: types.POLICY_SEARCH_TRIGGER,
    }),
    policyList() {
      return this.$store.state.PolicyList.policyList;
    },
    isBusy() {
      return this.isLoading;
    },
    paginationParams() {
      return {
        take: this.limit,
        skip: this.offSet,
        searchPolicyNumber: this.policyNumber,
        personName: this.personName,
      };
    },
    offSet() {
      return (this.currentPage - 1) * this.limit;
    },
  },

  watch: {
    async policySearchTrigger() {
      await this.clearPageAndSearch();
    },
  },
  beforeDestroy() {
    this.$store.commit(this.$types.POLICY_SET, null);
  },
  methods: {
    async clearPageAndSearch() {
      this.currentPage = 1;
      await this.policyGet();
    },
    async tableSizeHandler(mount) {
      this.perPage = mount;
      this.limit = this.perPage;
      await this.policyGet();
    },
    async policyGet() {
      if (this.isBusy) return;
      if (this.policyNumber === '') this.policyNumber = null;
      if (this.personName === '') this.personName = null;

      const data = await this.$store.dispatch(this.$types.GET_POLICY_BY_NUMBER, this.paginationParams);
      this.allCount = data.xTotalCount;
    },
    openEditUserModal(row) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PolicyViewModal',
        props: {
          currentPolicyData: row,
          paginationParams: this.paginationParams,
        },
      });
    },
    openDeleteUserModal(id) {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'DeletePolicyModal',
          props: {
            policyId: id,
          },
        },
      );
    },
    openCombinePolicyModal() {
      uiService.showModal(MODALS.COMBINE_POLICIES_MODAL, {
        name: 'StoryDeleteModal',
      });
    },
    startDateFormated(row) {
      return row.item.startDate ? format(parseISO(row.item.startDate), 'dd.MM.yyyy HH:mm') : null;
    },
    endDateFormated(row) {
      return row.item.endDate ? format(parseISO(row.item.endDate), 'dd.MM.yyyy HH:mm') : null;
    },
    birthdayDateFormated(row) {
      return row.item.birthday ? format(parseISO(row.item.birthday), 'dd.MM.yyyy') : null;
    },
    openPolicyCreateModal() {
      uiService.showModal(MODALS.POLICY_CREATE_MODAL, {
        name: 'PolicyCreateModal',
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.policy-list-content {
  height: calc(100vh - #{$header-height});
  transition: height 0.3s linear;
}

.crm-table {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.crm-input {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.crm-pagination {
  display: flex;
  align-items: center;
}

.crm-icon-delete {
  cursor: pointer;
  transition: .3s all;

  &:hover {
    rect {
      stroke: $blue;
      opacity: 0.5;
    }
  }
}

.crm-icon-edit {
  cursor: pointer;
  transition: .3s all;

  path {
    fill: $blue;
  }

  &:hover {
    rect {
      stroke: $blue;
      opacity: 0.5;
    }
  }
}
</style>
